<template>
  <h2>
    {{ customObjectData.name }}
  </h2>
  <p>
    <span class="badge rounded-pill badge-info text-dark">{{
      customObjectData.key
    }}</span
    >&nbsp;
    <span
      class="badge rounded-pill badge-success"
      v-if="this.$store.getters._activeLangTR"
      ><i class="bi bi-clock"></i> {{ dateTime(customObjectData.createdAt) }}
      {{
        $t(
          "CustomObjects.CreatedOn",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</span
    >
    &nbsp;
    <span class="badge rounded-pill badge-success" v-else
      ><i class="bi bi-clock"></i>
      {{
        $t(
          "CustomObjects.CreatedOn",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
      {{ dateTime(customObjectData.createdAt) }}</span
    >
    &nbsp;
    <span
      class="badge rounded-pill badge-warning"
      v-if="this.$store.getters._activeLangTR"
      ><i class="bi bi-clock"></i> {{ dateTime(customObjectData.updatedAt) }}
      {{
        $t(
          "CustomObjects.UpdatedOn",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</span
    >
    <span class="badge rounded-pill badge-warning" v-else
      ><i class="bi bi-clock"></i>
      {{
        $t(
          "CustomObjects.UpdatedOn",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
      {{ dateTime(customObjectData.updatedAt) }}
    </span>
  </p>
  <div class="exclude-divs">
    <div class="row mt-4">
      <div class="col border-bottom">
        <label>{{
          $t("CustomObjects.Name", {}, { locale: this.$store.state.activeLang })
        }}</label
        ><br />
        <b>{{ customObjectData.name }}</b>
      </div>
      <div class="col border-bottom">
        <label>{{
          $t(
            "CustomObjects.PluralName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
        ><br />
        <b>{{ customObjectData.pluralName }}</b>
      </div>
      <div class="col border-bottom">
        <label>{{
          $t(
            "CustomObjects.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
        ><br />
        <b>{{ customObjectData.formulaName }}</b>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col border-bottom">
        <label>{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
        ><br />
        <b v-if="customObjectData.isActive"
          ><i class="bi bi-check bi-xlg text-success"></i
        ></b>
        <b v-else><i class="bi bi-x bi-xlg text-danger"></i></b>
      </div>
      <div class="col border-bottom">
        <label>{{
          $t(
            "CustomObjects.RecordCopiedIsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
        ><br />
        <b v-if="customObjectData.isRecordsCopied"
          ><i class="bi bi-check bi-xlg text-success"></i
        ></b>
        <b v-else><i class="bi bi-x bi-xlg text-danger"></i></b>
      </div>
      <div class="col border-bottom">
        <label>{{
          $t(
            "CustomObjects.IsCalculatedOnlyOnInsert",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
        ><br />
        <b v-if="customObjectData.isCalculatedOnlyOnInsert"
          ><i class="bi bi-check bi-xlg text-success"></i
        ></b>
        <b v-else><i class="bi bi-x bi-xlg text-danger"></i></b>
      </div>
    </div>
    <div class="row mt-2">
      <div class="w-100 border-bottom">
        <label
          class="cursor-pointer no-selection fw-bold"
          @click="isShowExampleLinks = !isShowExampleLinks"
        >
          <template v-if="isShowExampleLinks">
            {{
              $t(
                "CustomObjects.HideExampleLinks",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </template>
          <template v-else>
            {{
              $t(
                "CustomObjects.ShowExampleLinks",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </template>
        </label>
      </div>
      <div class="col-12 mt-2" v-if="isShowExampleLinks">
        <span
          class="badge badge-dark me-2 mb-1 fw-normal fs-15"
          v-for="(item, i) in exampleLinks"
          :key="i"
          v-html="
            item
              .replaceAll('CUSTOMOBJECTKEY', customObjectData.key)
              .replaceAll(
                'filterId=X',
                String.format('<b style=color:var(--bs-warning)>filterId=X</b>')
              )
              .replaceAll(
                'pageLayoutId=X',
                String.format(
                  '<b style=color:var(--bs-warning)>pageLayoutId=X</b>'
                )
              )
              .replaceAll(
                'CALENDAR_PUBLICID_ID',
                String.format(
                  '<b style=color:var(--bs-warning)>CALENDAR_PUBLICID_ID</b>'
                )
              )
              .replaceAll(
                'CUSTOM_PAGE_FORMULA_NAME',
                String.format(
                  '<b style=color:var(--bs-warning)>CUSTOM_PAGE_FORMULA_NAME</b>'
                )
              )
              .replaceAll('?', '<b style=color:var(--bs-danger)>?</b>')
          "
        ></span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-warning">
                <i class="bi bi-card-list"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.WFieldCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>

                <span class="widget-stats-amount">
                  {{ customObjectDetail.fieldCount }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-success">
                <i class="bi bi-app"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.ButtonCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>

                <span class="widget-stats-amount">{{
                  customObjectDetail.buttonCount
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-danger">
                <i class="bi bi-lightning"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.BusinessRuleCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>

                <span class="widget-stats-amount">{{
                  customObjectDetail.businessRuleCount
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-danger">
                <i class="bi bi-code-slash"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.ScriptCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>

                <span class="widget-stats-amount">{{
                  customObjectDetail.scriptCount
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-primary">
                <i class="bi bi-tools"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.ValidationRuleCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>
                <span class="widget-stats-amount">{{
                  customObjectDetail.validationRuleCount
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card widget widget-stats mb-0">
          <div class="card-body p-3">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-success">
                <i class="bi bi-shuffle"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "CustomObjects.ObjectRelationCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>
                <span class="widget-stats-amount">{{
                  customObjectDetail.objectRelationCount
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomObjectDetail",
  data() {
    return {
      customObjectData: this.$store.state.customObjectDto || {},
      customObjectDetail:
        this.$store.state.customObjectWithChildCountList || {},
      isShowExampleLinks: false,
      exampleLinks: [
        "#/set/list/CUSTOMOBJECTKEY",
        "#/set/list/CUSTOMOBJECTKEY?filterId=X",
        "#/set/calendar/CUSTOMOBJECTKEY/CALENDAR_PUBLICID_ID?filterId=X",
        "#/set/new/CUSTOMOBJECTKEY",
        "#/set/new/CUSTOMOBJECTKEY?pageLayoutId=X",
        "#/set/detail/CUSTOMOBJECTKEY",
        "#/set/detail/CUSTOMOBJECTKEY?pageLayoutId=X",
        "#/set/edit/CUSTOMOBJECTKEY",
        "#/set/edit/CUSTOMOBJECTKEY?pageLayoutId=X",
        "#/set/history/CUSTOMOBJECTKEY",
        "#/set/content/CUSTOM_PAGE_FORMULA_NAME",
      ],
    };
  },
  methods: {
    dateTime(value) {
      var format = this.$store.getters._dateTimeFormat;
      if (String.isNullOrWhiteSpace(value) || String.isNullOrWhiteSpace(format))
        return;
      return this.$moment(value).format(format.toUpperCase());
    },
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.customObjectData = newValue;
      }
    },
    "$store.state.customObjectWithChildCountList"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.customObjectDetail = newValue;
      }
    },
  },
  mounted() {},
};
</script>
